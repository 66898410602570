.social-buttons-container {
  display: flex;
  align-items: center;
  justify-content: start;
  .share-button {
    margin: 4px;
    &:hover {
      transition: all .2s ease-in-out !important;
      transform: scale(1.2);
    }
  }
}