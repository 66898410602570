.site-card-wrapper {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.landing-card {
	width: 75%;
	height: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px !important;
	box-shadow: 5px 5px 10px -3px grey;
	border: 1px solid darkgrey;
}

.connect-wallet-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
}

.landing-title {
	font-family: 'Poppins';
	font-size: 36.25px;
	font-weight: bold;
}

.landing-description {
	font-family: 'Roboto';
	font-size: 16.11px;
}

.landing-connect-btn {
	width: 148px;
	height: 45px !important;
	background: #d5b75f !important;
	border: none !important;
	box-shadow: 0 5px 9px 0px grey !important;
}

.metamask-title {
	font-size: 20px;
	font-weight: bold;
}

